var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", style: { minWidth: _vm.widthPdf + "px" } },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "mt-1",
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm._e(),
      _vm._l(_vm.numPages, function (i) {
        return _c("pdf", {
          key: i,
          staticStyle: {
            display: "inline-block",
            width: "100%",
            height: "auto",
            margin: "2px 0",
          },
          attrs: { src: _vm.src, page: i },
          on: {
            loaded: function ($event) {
              _vm.loading = false
            },
          },
        })
      }),
      _c("div", { staticClass: "button__box" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.pluspdfwidth(_vm.widthPdf)
              },
            },
          },
          [_vm._v("+")]
        ),
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.minspdfwidth(_vm.widthPdf)
              },
            },
          },
          [_vm._v("-")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }